<template>
  <div id="app" style="">
    <div style="width: 100%;justify-content: center;display: flex">
      <router-view/>
      <!-- 在线客服 -->
    </div>
    <div class="customerService">
      <div class="leftBox">
        <p>在<br/>线<br/>客<br/>服</p>
        <i class="el-icon-chat-dot-round"></i>
      </div>
      <div class="info">
        <div style="border-bottom: 1px solid #eaeaea" @click="openQQ">
          <div style="margin-bottom: 8px">
            <img src="../src/assets/QQ.png" alt="">
          </div>
          <p style="color: #0080ff">{{ qq }}</p>
        </div>
        <div>
          <i
              class="el-icon-message"
              style="font-size: 42px; color: #666; margin-bottom: 8px"
          ></i>
          <p>{{ email }}</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {findQQ} from "/src/api/front/home";

export default {
  name: "App",
  data() {
    return {
      email: "",
      qq: "",
    };
  },
  created() {
    findQQ().then((res) => {
      this.email = res.data.data.mail;
      this.qq = res.data.data.qq;
      window.sessionStorage.setItem("email", res.data.data.mail);
      window.sessionStorage.setItem("qq", res.data.data.qq);
    });
  },
  methods: {
    openQQ() {
      window.open(`http://wpa.qq.com/msgrd?v=3&uin=${this.qq}&site=qq&menu=yes`)
    }
  }
};
</script>
<style>
.customerService {
  position: fixed;
  right: 0;
  top: 264px;
  height: 198px;
  z-index: 999;
  display: flex;
  margin-right: -148px;
  transition: all 1s ease;
}

.customerService p {
  margin: 0;
}

.customerService:hover {
  transform: translateX(-148px);
}

.info {
  width: 148px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
}

.info > div {
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #666666;
}

.leftBox {
  width: 42px;
  height: 100%;
  background: #ec4646;
  border-radius: 20px 0 0 20px;
  color: #fff;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.leftBox p {
  margin-bottom: 13px;
}

#app {
  display: flex;
  justify-content: center;
  background-color: #000;
  height: 150%;
  min-width: 1200px;
  max-width: 2560px

}
</style>
