import axios from 'axios'
import {serialize} from '../utils'
import NProgress from 'nprogress' // progress bar
import errorCode from '/src/const/errorCode'
import {Message, MessageBox} from 'element-ui'
import 'nprogress/nprogress.css'
import qs from 'qs'
import store from '../store'
import router from "./index";
import cookie from 'js-cookie';

axios.defaults.timeout = 30000
// 返回其他状态吗
axios.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 500 // 默认的
}
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true
// NProgress Configuration
NProgress.configure({
    showSpinner: false
})

// HTTPrequest拦截
axios.interceptors.request.use(config => {
    const token = cookie.get('access_token');
    if (config.url  !== '/admin/oauth2/token' &&
        !config.headers.from &&
        !token) {
        MessageBox.confirm('当前未登录,请登录后查看', '系统提示', {
                confirmButtonText: '立即登录',
                cancelButtonText: '取消',
                type: 'warning'
            }
        ).then(() => {
            router.push('/login');
        }).catch(() => {
        });
        return;
    }
    NProgress.start() // start progress bar
    const isToken = (config.headers || {}).isToken === false
    if (token && !isToken) {
        config.headers['Authorization'] = 'Bearer ' + token// token
    }

    // headers中配置serialize为true开启序列化
    if (config.method === 'post' && config.headers.serialize) {
        config.data = serialize(config.data)
        delete config.data.serialize
    }

    if (config.method === 'get') {
        config.paramsSerializer = function (params) {
            return qs.stringify(params, {arrayFormat: 'repeat'})
        }
    }

    return config
}, error => {
    return Promise.reject(error)
})

// HTTPresponse拦截
axios.interceptors.response.use(res => {
    NProgress.done()
    const status = Number(res.status) || 200
    const message = res.data.msg || errorCode[status] || errorCode['default']
    // 后台定义 424 针对令牌过去的特殊响应码
    if (status === 424) {
        MessageBox.confirm('令牌状态已过期，请点击重新登录', '系统提示', {
                confirmButtonText: '重新登录',
                cancelButtonText: '取消',
                type: 'warning'
            }
        ).then(() => {
            store.dispatch('LogOut').then(() => {
                // 刷新登录页面，避免多次弹框
                window.location.reload()
            })
        }).catch(() => {
        });
        return
    }

    if (status !== 200 || res.data.code === 1) {
        // if (res.config.url === "/admin/member/user/info"){
        //     // 查询不到当前用户信息,删除cookie,转登录
        //     cookie.remove('access_token');
        //     MessageBox.confirm('当前未登录', '系统提示', {
        //             confirmButtonText: '立即登录',
        //             cancelButtonText: '取消',
        //             type: 'warning'
        //         }
        //     ).then(() => {
        //         router.push('/login');
        //     }).catch(() => {
        //     });
        // }
        Message({
            message: message,
            type: 'error'
        })
        return Promise.reject(new Error(message))
    }
    return res
}, error => {
    // 处理 503 网络异常
    if (error.response.status === 503) {
        Message({
            message: error.response.data.msg,
            type: 'error'
        })
    }
    NProgress.done()
    return Promise.reject(new Error(error))
})

export default axios
