import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routes = [

    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/front',
        name: 'Front',
        component: () => import("../home/front"),
        children: [
            {
                path: 'home',
                name: 'FrontHome',
                component: () => import("../views/front/home")
            },

            {
                path: 'edu/:dataType',
                name: 'edu',
                component: () => import("../views/front/edu/index")
            },
            {
                path: 'edu/detail/:id',
                name: 'eduDetail',
                component: () => import("../views/front/edu/eduDetail")
            },
            {
                path: 'school',
                name: 'school',
                component: () => import("../views/front/school/index")
            },
            {
                path: 'member',
                name: 'member',
                component: () => import("../views/front/member/index")
            },
            {
                path: 'topic/:dataType',
                name: 'topic',
                component: () => import("../views/front/topic/index")
            },
            {
                path: 'details',
                name: 'details',
                component: () => import("../views/front/topic/details.vue")
            },

        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import("../views/login")
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import("../views/register")
    },
]


const createRouter = () => new VueRouter({
    mode: 'hash',
    routes: routes
})

const router = createRouter()

resetRouter()

//写一个重置路由的方法，切换用户后，或者退出时清除动态加载的路由
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // 新路由实例matcer，赋值给旧路由实例的matcher，（相当于replaceRouter）

}


export default router
