import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './style/base.css';
import {downBlobFile} from './utils/index'
// 引入echarts
import * as echarts from 'echarts';
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';


VMdPreview.use(githubTheme, {});
VueMarkdownEditor.use(githubTheme, {});
Vue.use(VMdPreview);
Vue.use(VueMarkdownEditor);


Vue.prototype.downBlobFile = downBlobFile

Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

Vue.use(ElementUI, {size: 'small'});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
