import request from '/src/router/axios'

export function findHomeConsult() {
    return request({
        url: '/admin/consult/home',
        headers: {
            'from': 'Y'
        },
        method: 'get',
    })
}

export function getFileType() {
    return request({
        url: '/admin/file/type',
        headers: {
            'from': 'Y'
        },
        method: 'get',
    })
}

export function getHomeImg() {
    return request({
        url: '/admin/carousel/home',
        headers: {
            'from': 'Y'
        },
        method: 'get',
    })
}

//获取地址

export function getAddress() {
    return request({
        url: '/admin/address',
        method: 'get',
    })
}

export function getFriendship(id) {
    return request({
        url: '/admin/friendship/link/home',
        headers: {
            'from': 'Y'
        },
        method: 'get',
        params: {
            addressId: id
        }
    })
}


export function getConsultByStatus(query) {
    return request({
        url: '/admin/consult/tag',
        method: 'get',
        params: query
    })
}

// 获取会员级别
export function getRandById(query) {
    return request({
        url: '/admin/member/rand/find/id',
        method: 'get',
        params: query
    })
}

// 修改密码
export function updatePassword(query) {
    return request({
        url: '/admin/member/user/update/password',
        method: 'post',
        data: query
    })
}

// 下载统计
export function fetchMemberPage(query) {
    return request({
        url: '/admin/member/download/user/page',
        method: 'get',
        params: query
    })
}

// 我要留言
export function userMessage(content) {
    return request({
        url: '/admin/leave/message/user/message',
        method: 'put',
        data: content
    })
}

// 会员留言
export function cardMessage() {
    return request({
        url: '/admin/leave/message/user/message',
        method: 'get',
    })
}

// 校长申请列表
export function findPrincipal() {
    return request({
        url: '/admin/member/user/principal',
        method: 'get',
    })
}

// 申请为校长
export function userApplyPrincipal() {
    return request({
        url: '/admin/member/user/principal/apply',
        method: 'get',
    })
}

//获取学校列表
export function fetchPage(query) {
    return request({
        url: '/admin/friendship/link/page',
        method: 'get',
        params: query
    })
}

// 获取邮箱和qq
export function findQQ() {
    return request({
        url: '/admin/qq',
        method: 'get',
        headers: {
            'from': 'Y'
        },
    })
}

export function findProduct() {
    return request({
        url: '/admin/product',
        method: 'get',
    })
}

/**
 * 查询通告内容
 */
export function findCircularList() {
    return request({
        url: '/admin/circular/home',
        method: 'get',
        headers: {
            'from': 'Y'
        },
    })
}


