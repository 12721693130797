import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import common from './modules/common'
import tags from './modules/tags'
import logs from './modules/logs'
import getters from './getters'


Vue.use(Vuex)

const state = {
    carts: [],
    type: 0
}

const mutations = {
    setCarts(state, carts) {
        state.carts = carts
    },
    setType(state, type) {
        state.type = type
    }
}

const store = new Vuex.Store({
    modules: {
        user,
        common,
        logs,
        tags
    },
    getters
})

export default store
