import {getStore, setStore} from "../utils/store";
import request from "../router/axios";
import qs from 'qs'
import website from '../const/website'


const scope = 'server'
const userType = 'user';

export const loginByUsername = (username, password, code, randomStr) => {
  let grant_type = 'password'
  let dataObj = qs.stringify({'username': username, 'password': password})
  let basicAuth = 'Basic ' + window.btoa(website.formLoginClient)

  // 保存当前选中的 basic 认证信息
  setStore({
    name: 'basicAuth',
    content: basicAuth,
    type: 'session'
  })
  return request({
    url: '/admin/oauth2/token',
    headers: {
      isToken: false,
      'TENANT-ID': '1',
      'Authorization': basicAuth
    },
    method: 'post',
    params: {randomStr, code, grant_type, scope,userType},
    data: dataObj
  })
}

export const refreshToken = (refresh_token) => {
  const grant_type = 'refresh_token'
  // 获取当前选中的 basic 认证信息
  let basicAuth = getStore({name: 'basicAuth'})

  return request({
    url: '/admin/oauth2/token',
    headers: {
      'isToken': false,
      'TENANT-ID': '1',
      'Authorization': basicAuth
    },
    method: 'post',
    params: {refresh_token, grant_type, scope}
  })
}

export const getUserInfo = () => {
  return request({
    url: '/admin/member/user/info',
    method: 'get'
  })
}

export const logout = () => {
  return request({
    url: '/admin/token/logout',
    method: 'delete'
  })
}

/**
 * 校验令牌，若有效期小于半小时自动续期
 * @param refreshLock
 */
export const checkToken = (refreshTime, refreshLock, $store) => {
  const token = store.getters.access_token
  // 获取当前选中的 basic 认证信息
  const basicAuth = getStore({ name: 'basicAuth' })

  request({
    url: '/admin/token/check_token',
    headers: {
      isToken: false,
      Authorization: basicAuth
    },
    method: 'get',
    params: { token }
  })
      .then((response) => {
        if (validatenull(response)) {
          clearInterval(refreshTime)
          return
        }

        const expire = response && response.data && response.data.exp
        if (expire) {
          const expiredPeriod = expire * 1000 - new Date().getTime()
          //小于半小时自动续约
          if (expiredPeriod <= 10 * 60 * 1000) {
            if (!refreshLock) {
              refreshLock = true
              $store.dispatch('RefreshToken').catch(() => {
                clearInterval(refreshTime)
              })
              refreshLock = false
            }
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
}
